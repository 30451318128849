<template>
  <div v-if="video" class="px-3 pt-3 p_b__3">
    <b-card class="border-0 shadow-sm">
      <b-container ref="defaultContainer" fluid class="bv-example-row">
        <b-row>
          <b-col cols="12" lg="4" xl="5" class="p-0">
            <video-selector></video-selector>
          </b-col>

          <b-col cols="12" md="6" lg="4" :xl="jsonVersion < 6 ? '2' : '3'">
            <!-- JSON VERSION < 6 -->
            <template v-if="jsonVersion < 6">
              <div class="score-response">
                <div
                  :style="{
                    background: globalPerformanceColor
                  }"
                  class="score-number"
                >
                  {{ video.globalPerformance }}
                </div>
              </div>
            </template>

            <!-- JSON VERSION >= 6 -->
            <template v-else>
              <title-info
                class="mb-5"
                size="large"
                justify="center"
                :info="$t('page.results.score.globalPerformance.tooltip')"
              >
                {{ $t("page.results.score.globalPerformance.title") }}
              </title-info>

              <b-overlay
                variant="white"
                class="h-100"
                :show="!valence || valence === 'error'"
              >
                <template class="text-center" slot="overlay">
                  Error in data provided
                </template>
                <gauge-chart
                  class="pt-3"
                  :valence="valence"
                  :globalPerformace="video.globalPerformance"
                  :norm="norms.globalPerformance"
                ></gauge-chart>
              </b-overlay>
            </template>
          </b-col>

          <b-col cols="12" md="6" lg="4" :xl="jsonVersion < 6 ? '5' : '4'">
            <!-- JSON VERSION < 6 -->
            <template v-if="jsonVersion < 6">
              <title-info
                class="mt-4"
                size="small"
                justify="start"
                condensed
                :info="$t('page.results.score.globalPerformance.old.tooltip')"
              >
                {{ $t("page.results.score.globalPerformance.old.title") }}
              </title-info>
              <h1
                class="font-weight-bold"
                :style="{ color: globalPerformanceColor }"
              >
                {{ globalPerformanceResponse }}
              </h1>
              <h4 class="mt-4">{{ globalPerformanceTitle }}</h4>
              <p>
                {{ globalPerformanceText }}
              </p>
            </template>

            <!-- JSON VERSION >= 6 -->
            <template class="pt-5" v-else>
              <title-info class="mb-5" size="large" justify="center">
              </title-info>
              <h2>{{ globalPerformanceTitle }}</h2>
              <p class="mb-5" style="font-weight: 300">
                {{ globalPerformanceText }}
              </p>
              <title-info
                condensed
                :info="$t('page.results.score.valence.tooltip')"
              >
                {{ $t("page.results.score.valence.title") }}
              </title-info>
              <p style="font-weight: 300">
                {{ valenceText }}
              </p>
            </template>
          </b-col>
        </b-row>

        <b-row :key="jsonVersion" class="mt-5">
          <b-col cols="12" md="6" lg="4" class="border-right border-1 pr-4">
            <score-bar
              v-if="jsonVersion < 6"
              metric="mediaEffectiveness"
              :score="video.mediaEffectiveness"
              :norm="norms.effectiveness"
            ></score-bar>
            <score-bar
              v-else
              metric="attention"
              :score="video.attention"
              :norm="norms.attention"
            ></score-bar>
          </b-col>

          <b-col cols="12" md="6" lg="4" class="border-right border-1 px-4">
            <score-bar
              v-if="jsonVersion < 6"
              metric="attention"
              :score="video.attention"
              :norm="norms.attention"
            ></score-bar>
            <score-bar
              v-else
              metric="attentionSpan"
              :score="video.attentionSpan"
              :norm="norms.attentionSpan"
            ></score-bar>
          </b-col>
          <b-col cols="12" md="6" lg="4" class="pl-4">
            <score-bar
              metric="engagement"
              :score="video.engagement"
              :norm="norms.engagement"
            ></score-bar
          ></b-col>
        </b-row>
      </b-container>
    </b-card>

    <div ref="showMoreButton" v-if="!displayMore" f class="text-center mt-5">
      <b-button @click="onClick(true)" variant="outline-secondary">
        {{ $t("button.showMore") }}</b-button
      >
    </div>

    <transition name="fade">
      <b-container
        ref="showMoreContainer"
        v-if="displayMore"
        fluid
        class="p-0 mt-5"
      >
        <b-row no-gutters align-v="end">
          <!-- <b-col cols="12" md="6" lg="4">
            <b-card class="border-0 shadow-sm">
              <score-bar
                metric="mediaEffectiveness"
                :score="video.mediaEffectiveness"
                :norm="norms.effectiveness"
              ></score-bar>
            </b-card>
          </b-col> -->
          <b-col cols="12" md="6" lg="4">
            <title-info size="medium" light condensed>
              {{ $t("page.results.score.digitalPerformance") }}
            </title-info>
            <b-card class="border-0 shadow-sm">
              <score-bar
                metric="predictiveClick"
                :score="video.digitalPerformance"
                :norm="norms.digitalPerformance"
              ></score-bar>
            </b-card>
          </b-col>
          <b-col cols="12" md="6" lg="4">
            <title-info
              size="medium"
              light
              condensed
              :info="$t('page.results.score.mediaOptimization.tooltip')"
            >
              {{ $t("page.results.score.mediaOptimization.title") }}
            </title-info>
            <b-card class="border-0 shadow-sm">
              <age-gender ageValue="80" genderValue="80"></age-gender>
            </b-card>
          </b-col>
        </b-row>

        <div class="text-center mt-5">
          <b-button @click="onClick(false)" variant="outline-secondary">
            {{ $t("button.showLess") }}</b-button
          >
        </div>
      </b-container>
    </transition>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import TitleInfo from "../components/Results/Score/TitleInfo.vue";
import ScoreBar from "../components/Results/Score/ScoreBar";
import GaugeChart from "../components/Results/Score/GaugeChart.vue";
import VideoSelector from "../components/Results/VideoSelector.vue";
import AgeGender from "../components/Results/Score/AgeGender.vue";
export default {
  components: {
    VideoSelector,
    TitleInfo,
    ScoreBar,
    GaugeChart,
    AgeGender
  },

  data() {
    return {
      key: 0,
      displayMore: false
    };
  },

  created() {
    if (!this.showMetrics) {
      this.$router.replace({ name: "graph" });
    }
  },

  computed: {
    ...mapState("analysis", ["comparison", "norms", "jsonVersion"]),

    ...mapGetters("analysis", [
      "video",
      "globalPerformanceTitle",
      "globalPerformanceText",
      "globalPerformanceColor",
      "globalPerformanceResponse",
      "valence",
      "valenceText"
    ]),

    ...mapState("project", ["activeProject"]),

    showMetrics() {
      return this.activeProject.step !== -1;
    }
  },

  methods: {
    onClick(displayMore) {
      this.displayMore = displayMore;

      this.$nextTick(() => {
        const container = this.$refs.showMoreContainer;
        const showMoreButton = this.$refs.showMoreButton;

        if (showMoreButton) {
          console.log(showMoreButton.getBoundingClientRect().top);
          window.scrollTo({
            top: 500,
            behavior: "smooth"
          });
        }

        if (container) {
          window.scrollTo({
            top: container.getBoundingClientRect().bottom,
            behavior: "smooth"
          });
        }
      });
    }
  }
};
</script>

<style scoped>
.card {
  min-height: 500px;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

@media screen and (min-width: 769px) and (max-width: 1334px) {
  .card {
    min-height: 560px;
  }
}

@media screen and (max-width: 992px) {
  .col-12 {
    margin-top: 2rem;
  }
}

.col-12:not(:last-child) .card {
  margin-right: 2rem;
}

.score-response {
  text-align: center;
  font-size: 1.8em;
  padding-top: 2rem;
  font-weight: 700;
  line-height: 100%;
  text-transform: capitalize;
}

.score-number {
  font-size: 5rem;
  padding: 3.4rem;
  height: 8rem;
  width: 8rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: 900;
  color: white;
  border-radius: 50%;
}

.key {
  font-size: 0.8rem;
  font-weight: 400;
}

.value {
  font-size: 0.8rem;
  font-weight: 700;
}

.custom-select {
  font-size: 0.8rem;
  font-weight: 400;
}
</style>
